import { ComponentType, useEffect, useState } from "react"

export function withHighlightColor1(Component): ComponentType {
    return (props: any) => {
        const [isVisible, setIsVisible] = useState(false)

        useEffect(() => {
            const elements = document.querySelectorAll(
                `.${props.className} [style*="--framer-text-decoration"]`
            )

            // Now, let's add a parent <mark> tag to the selected elements
            elements.forEach((element) => {
                element.style.removeProperty("--framer-text-decoration")

                const markElement = document.createElement("mark")
                const spanElement = document.createElement("span")

                // Wrap the element with mark
                element.parentNode.insertBefore(markElement, element)
                markElement.appendChild(element)

                // Wrap the content of the element with span
                while (element.firstChild) {
                    spanElement.appendChild(element.firstChild)
                }

                element.appendChild(spanElement)
                setIsVisible(true)
            })

            const handle = (entries) => {
                entries.forEach((entry) => {
                    const highlightedValue =
                        entry.target.style.getPropertyValue("--highlighted")
                    console.log("handle")
                    if (!highlightedValue || parseInt(highlightedValue) === 0) {
                        entry.target.style.setProperty(
                            "--highlighted",
                            entry.isIntersecting ? "1" : "0"
                        )
                    }
                })
            }
            const observer = new IntersectionObserver(handle, {
                threshold: 1.0,
            })
            elements.forEach((M) => observer.observe(M))
        }, [props])

        return (
            <>
                <style>{`
.${props.className} mark {
--highlighted: 0;
--highlight: rgba(197, 58, 107, 1.0);
background: transparent;
}

.${props.className} mark span {
background: linear-gradient(120deg, var(--highlight) 50%, transparent 50%) 110% 0 / 250% 100% no-repeat;
background-position: calc((1 - var(--highlighted)) * 150%) 0;
transition: background-position 1s;
color: white;
}
`}</style>
                <Component
                    {...props}
                    style={!isVisible ? { opacity: 0 } : {}}
                />
            </>
        )
    }
}

export function withHighlightColor2(Component): ComponentType {
    return (props: any) => {
        const [isVisible, setIsVisible] = useState(false)

        useEffect(() => {
            const elements = document.querySelectorAll(
                `.${props.className} [style*="--framer-text-decoration"]`
            )

            // Now, let's add a parent <mark> tag to the selected elements
            elements.forEach((element) => {
                element.style.removeProperty("--framer-text-decoration")

                const markElement = document.createElement("mark")
                const spanElement = document.createElement("span")

                // Wrap the element with mark
                element.parentNode.insertBefore(markElement, element)
                markElement.appendChild(element)

                // Wrap the content of the element with span
                while (element.firstChild) {
                    spanElement.appendChild(element.firstChild)
                }

                element.appendChild(spanElement)
                setIsVisible(true)
            })

            const handle = (entries) => {
                entries.forEach((entry) => {
                    const highlightedValue =
                        entry.target.style.getPropertyValue("--highlighted")
                    if (!highlightedValue || parseInt(highlightedValue) === 0) {
                        entry.target.style.setProperty(
                            "--highlighted",
                            entry.isIntersecting ? "1" : "0"
                        )
                    }
                })
            }
            const observer = new IntersectionObserver(handle, {
                threshold: 1.0,
            })
            elements.forEach((M) => observer.observe(M))
        }, [props])

        return (
            <>
                <style>{`
.${props.className} mark {
--highlighted: 0;
--highlight: rgba(255, 195, 0, 0.8);
background: transparent;
}

.${props.className} mark span {
background: linear-gradient(120deg, var(--highlight) 50%, transparent 50%) 110% 0 / 200% 100% no-repeat;
background-position: calc((1 - var(--highlighted)) * 110%) 0;
transition: background-position 1s;
color: black;
}
`}</style>
                <Component
                    {...props}
                    style={!isVisible ? { opacity: 0 } : {}}
                />
            </>
        )
    }
}

export function withHighlightColor3(Component): ComponentType {
    return (props: any) => {
        const [isVisible, setIsVisible] = useState(false)

        useEffect(() => {
            const elements = document.querySelectorAll(
                `.${props.className} [style*="--framer-text-decoration"]`
            )

            // Now, let's add a parent <mark> tag to the selected elements
            elements.forEach((element) => {
                element.style.removeProperty("--framer-text-decoration")

                const markElement = document.createElement("mark")
                const spanElement = document.createElement("span")

                // Wrap the element with mark
                element.parentNode.insertBefore(markElement, element)
                markElement.appendChild(element)

                // Wrap the content of the element with span
                while (element.firstChild) {
                    spanElement.appendChild(element.firstChild)
                }

                element.appendChild(spanElement)
                setIsVisible(true)
            })

            const handle = (entries) => {
                entries.forEach((entry) => {
                    const highlightedValue =
                        entry.target.style.getPropertyValue("--highlighted")
                    if (!highlightedValue || parseInt(highlightedValue) === 0) {
                        entry.target.style.setProperty(
                            "--highlighted",
                            entry.isIntersecting ? "1" : "0"
                        )
                    }
                })
            }
            const observer = new IntersectionObserver(handle, {
                threshold: 1.0,
            })
            elements.forEach((M) => observer.observe(M))
        }, [props])

        return (
            <>
                <style>{`
.${props.className} mark {
--highlighted: 0;
--highlight: rgba(140, 0, 255, 0.5);
background: transparent;
}

.${props.className} mark span {
background: linear-gradient(120deg, var(--highlight) 50%, transparent 50%) 110% 0 / 200% 100% no-repeat;
background-position: calc((1 - var(--highlighted)) * 110%) 0;
transition: background-position 1s;
color: white;
}
`}</style>
                <Component
                    {...props}
                    style={!isVisible ? { opacity: 0 } : {}}
                />
            </>
        )
    }
}
